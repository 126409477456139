import axios from 'axios';
import {  observable, runInAction, action } from 'mobx';
import * as R from 'ramda'
import { path, v1 } from '../utils/api';
import buildActionTypes from '../utils/buildAction';
import StateHandling from '../utils/stateHandling'

export const LOGIN = buildActionTypes("LOGIN")

const initialState = {
  "login": null
}

class AuthStore extends StateHandling {
  @observable state = initialState
  @observable data = null
  @observable datas = null
  @observable error = null
  // @observable currentUser = null
  @observable isLoading = false

  @action clearState = () => {
    if (!R.isNil(this.state)) { this.state = initialState }
  }

  @action clearData = () => {
    if (!R.isNil(this.data)) {
      this.data = null
    }
    if (!R.isNil(this.datas)) {
      this.datas = null
    }
  }

  @action clearError = () => {
    this.error = null
  }

  @action login = async (data) => {
    try {
      runInAction(() => { 
        this.state.login = LOGIN.REQUEST
        this.isLoading = true
        this.clearError()
      })
      const response = await axios({
        method: "post",
        url: `${path}/${v1}/login/web`,
        data
      })
      runInAction(() => { 
        this.state.login = LOGIN.SUCCESS 
        this.isLoading = false
        const token = `Bearer ${response.data.data.token}`
        localStorage.setItem('@token', token)
        localStorage.setItem('@email', response.data.data.email)
        axios.defaults.headers.common.Authorization = token
        // this.currentUser = response.data.data
        // localStorage.setItem('@currentUser', JSON.stringify(response.data.data))
      })
    } catch (error) {
      runInAction(() => {
        this.state.login = LOGIN.FAILURE
        this.isLoading = false
        this.error = error
      })
    }
  }

  @action logout = async () => {
    // this.currentUser = null
    localStorage.removeItem('@token')
    localStorage.removeItem('@email')
    // localStorage.removeItem('@currentUser')
  }

}

export default AuthStore
