import React from "react";

import { BrowserRouter as Router, Route ,Redirect ,Switch} from "react-router-dom";
import { Provider} from "mobx-react";
import routes, { loginRoute } from "./routes";
import withTracker from "./withTracker";
import stores from "./stores";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/main.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axios from 'axios';
import { AxiosInterceptor } from './utils/interceptor';

export default () => (
  <Provider {...stores}>
    <Router basename={process.env.REACT_APP_BASENAME || ""}>
      <Switch>
      <Route
        key={999}
        path={loginRoute.path}
        exact={loginRoute.exact}
        component={withTracker(props => {
          return (
            <loginRoute.layout {...props}>
              <loginRoute.component {...props} />
            </loginRoute.layout>
          );
        })}
      />
        {
          routes.map((route, index) => {
            return (
              <PrivateRoute
                key={index}
                path={route.path}
                exact={route.exact}
                component={withTracker(props => {
                  return (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  );
                })}
              />
            );
          })
        }
      </Switch>
    </Router>
  </Provider>
);

class PrivateRoute extends React.Component {
  render() {
    AxiosInterceptor()
    const { ...restProps } = this.props;
    const token = localStorage.getItem('@token')
    if (token) {
      axios.defaults.headers.common.Authorization = token
      return <Route {...restProps} />;
    }

    return <Redirect to="/login" />;
  }
}
