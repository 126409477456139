import React from "react";
import { Button, Modal, ModalBody } from "shards-react";
import i18n from '../../i18n';

export const ModalPop = ({ title, text, confirmTextBtn, confirmFnBtn, calcelFnBtn, open, acceptConfirm = false, rejectFnBtn }) => {
  return (
    <Modal open={open} toggle={() => calcelFnBtn()} >
      <ModalBody className="text-center">
        <img className="rounded" src={require("../../images/icon-warning.png")} alt={"warning"} />
        <h3>{title || "Are you sure?"}</h3>
        <h6>{text || "You won't be able to revert this!"}</h6>
        <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: 30 }}>
          <Button onClick={() => confirmFnBtn()} style={{ width: '50%', margin: 2 }}>{confirmTextBtn}</Button>
          {
            acceptConfirm ?
            <Button theme="danger" onClick={() => rejectFnBtn()} style={{ width: '50%', margin: 2 }}>Reject</Button>
            :
            <Button theme="danger" onClick={() => calcelFnBtn()} style={{ width: '50%', margin: 2 }}>{i18n.t("Cancel")}</Button>
          }
        </div>
        {
          acceptConfirm &&
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }} className="text-center">
            <Button theme="info" onClick={() => calcelFnBtn()} style={{ width: '100%' }}>{i18n.t("Cancel")}</Button>
          </div>
        }
      </ModalBody>
    </Modal>
  )
}