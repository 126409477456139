import React from "react";
import {
  Progress
} from "shards-react";

const LoadingBar = ({isLoading, progress, theme}) => {
  return(
    isLoading &&
    <Progress
      className="progress-sm"
      theme={theme}
      value={progress}
      max="100"
      animated
    />
  )
}

export default LoadingBar