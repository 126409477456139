export default function () {
  return [
  {
    title: 'Management',
    htmlBefore: '<i class="material-icons">&#xE7FD;</i>',
    items: [{
      title: 'Users',
      htmlBefore: '<i class="material-icons">&#xE251;</i>',
      items: [
        {
          title: 'All User',
          to: '/users',
        }, {
          title: 'Pending Delete',
          to: '/pending-delete-user',
        }
      ]
    },
    {
      title: 'Shelters',
      htmlBefore: '<i class="material-icons">&#xE251;</i>',
      to: '/shelters',
    },
    {
      title: 'Pets',
      htmlBefore: '<i class="material-icons">&#xE251;</i>',
      to: '/pets',
    }]
  },
  {
    title: 'Settings',
    htmlBefore: '<i class="material-icons">&#xE7FD;</i>',
    open: false,
    items: [
    {
      title: 'Password',
      htmlBefore: '<i class="material-icons">&#xE251;</i>',
      to: '/change-password',
    },
    {
      title: 'Delete Reason',
      htmlBefore: '<i class="material-icons">&#xE251;</i>',
      to: '/reason',
    }]
  }
  ];
}
