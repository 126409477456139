import axios from 'axios';
import {  observable, runInAction, action } from 'mobx';
import * as R from 'ramda'
import { path, v1 } from '../utils/api';
import buildActionTypes from '../utils/buildAction';
import StateHandling from '../utils/stateHandling'

export const GET_ALL_SHELTER = buildActionTypes("GET_ALL_SHELTER")
export const GET_SHELTER = buildActionTypes("GET_SHELTER")
export const DELETE_SHELTER = buildActionTypes("DELETE_SHELTER")
export const GET_REPORT_HISTORY = buildActionTypes("GET_REPORT_HISTORY")

const initialState = {
  "get": null,
  "delete": null,
  "getReportHistory": null
}

const initialData = {
  id: "",
  contactName: "",
  contactSurName: "",
  shelterName: "",
  contactNumber: "",
  address: "",
  website: "",
  photoUrl: "",
  description: "",
  openingHours: {
    mon: {
      startTime: "",
      endTime: ""
    },
    tue: {
      startTime: "",
      endTime: ""
    },
    wed: {
      startTime: "",
      endTime: ""
    },
    thu: {
      startTime: "",
      endTime: ""
    },
    fri: {
      startTime: "",
      endTime: ""
    },
    sat: {
      startTime: "",
      endTime: ""
    },
    sun: {
      startTime: "",
      endTime: ""
    }
  }
}

class ShelterStore extends StateHandling {
  @observable state = initialState
  @observable data = initialData
  @observable datas = []
  @observable error = null
  @observable isLoading = false
  @observable totalPage = 0
  @observable report = []

  @action clearState = () => {
    if (!R.isNil(this.state)) { this.state = initialState }
  }

  @action clearData = () => {
    if (!R.isNil(this.data)) {
      this.data = initialData
    }
    if (!R.isNil(this.datas)) {
      this.datas = []
    }
    this.totalPage = 0
  }

  @action getAll = async (offset, limit) => {
    try {
      runInAction(() => { 
        this.state.get = GET_ALL_SHELTER.REQUEST
        this.isLoading = true
      })
      const response = await axios({
        method: "get",
        url: `${path}/${v1}/shelters?page=${offset}&limit=${limit}`,
      })
      runInAction(() => {
        this.state.get = GET_ALL_SHELTER.SUCCESS
        this.isLoading = false
        this.datas = response.data.data
        this.totalPage = response.data.totalPage
      })
    } catch (error) {
      runInAction(() => {
        this.state.get = GET_ALL_SHELTER.FAILURE
        this.isLoading = false
        this.error = error
      })
    }
  }

  @action getById = async (id) => {
    try {
      runInAction(() => { 
        this.state.get = GET_SHELTER.REQUEST
        this.isLoading = true
      })
      const response = await axios({
        method: "get",
        url: `${path}/${v1}/shelters/${id}`,
      })
      runInAction(() => {
        this.state.get = GET_SHELTER.SUCCESS
        this.isLoading = false
        this.data = response.data.data
      })
    } catch (error) {
      runInAction(() => {
        this.state.get = GET_SHELTER.FAILURE
        this.isLoading = false
        this.error = error
      })
    }
  }

  @action delete = async (id) => {
    try {
      runInAction(() => { 
        this.state.delete = DELETE_SHELTER.REQUEST
        this.isLoading = true
      })
      const response = await axios({
        method: "delete",
        url: `${path}/${v1}/shelters/${id}`,
      })
      runInAction(() => {
        this.state.delete = DELETE_SHELTER.SUCCESS
        this.isLoading = false
      })
    } catch (error) {
      runInAction(() => {
        this.state.delete = DELETE_SHELTER.FAILURE
        this.isLoading = false
        this.error = error
      })
    }
  }

  @action getReportHistory = async () => {
    try {
      runInAction(() => { 
        this.state.getReportHistory = GET_REPORT_HISTORY.REQUEST
        this.isLoading = true
      })
      const response = await axios({
        method: "get",
        url: `${path}/${v1}/reports/shelter`,
      })
      runInAction(() => {
        this.state.getReportHistory = GET_REPORT_HISTORY.SUCCESS
        this.isLoading = false
        this.report = response.data.data
      })
    } catch (error) {
      runInAction(() => {
        this.state.getReportHistory = GET_REPORT_HISTORY.FAILURE
        this.isLoading = false
        this.error = error
      })
    }
  }
}

export default ShelterStore
