import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import { inject, observer } from "mobx-react";
import { withTranslation } from 'react-i18next';

@inject("authStore")
@observer
class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      email: ""
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  componentDidMount() {
    this.init()
  }

  init = () => {
    const email = localStorage.getItem('@email')
    this.setState({ email })
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  logout = () => {
    this.props.authStore.logout()
    window.location.reload(false);
  }

  render() {
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
          {/* <img
            className="user-avatar rounded-circle mr-2"
            src={require("./../../../../images/avatars/0.jpg")}
            alt="User Avatar"
          /> */}
          {" "}
          <span className="d-md-inline-block text-right">{this.state.email} <br/>(Admin)</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          {/* <DropdownItem tag={Link} to="user-profile">
            <i className="material-icons">&#xE7FD;</i> Profile
          </DropdownItem> */}
          {/* <DropdownItem tag={Link} to="edit-user-profile">
            <i className="material-icons">&#xE8B8;</i> Edit Profile
          </DropdownItem>
          <DropdownItem tag={Link} to="file-manager-list">
            <i className="material-icons">&#xE2C7;</i> Files
          </DropdownItem>
          <DropdownItem tag={Link} to="transaction-history">
            <i className="material-icons">&#xE896;</i> Transactions
          </DropdownItem> */}
          {/* <DropdownItem divider /> */}
          <DropdownItem onClick={() => this.logout()} className="text-danger">
            <i className="material-icons text-danger">&#xE879;</i> {this.props.t('Log out')}
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}

export default withTranslation()(UserActions)