import React, { Component } from "react";
import ReactTable from "react-table";
import { Container, Row, Card, CardBody, Button, CardHeader, ButtonGroup, Col, FormSelect } from "shards-react";
import { inject, observer } from "mobx-react";
import { toJS } from 'mobx';
import PageTitle from "../../components/common/PageTitle";
import LoadingBar from '../../components/common/LoadingBar';
import { DELETE_PET, GET_ALL_PET } from "../../stores/PetStore";
import ModalDeletePet from "../../components/common/ModalDeletePet";
import ErrorAlert from "../../components/alert/ErrorAlert";
import { GET_ALL_REASON } from "../../stores/ReasonStore";
import { CSVLink } from "react-csv";
import { withTranslation } from 'react-i18next';

@inject('petStore', 'reasonStore')
@observer
class Pets extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 10,
      progress: 0,
      modalConfirm: {
        open: false,
        id: ""
      },
      page: 1,
      modalFailure: {
        open: true
      }
    }
  }

  componentDidMount(){
    this.init(this.state.page, this.state.pageSize)
  }

  componentDidUpdate(){
    const { state, clearState } = this.props.petStore
    const st = toJS(state)

    if(st.delete === DELETE_PET.SUCCESS){
      clearState()
      this.init(this.state.page, this.state.pageSize)
    } 
  }

  componentWillUnmount(){
    this.props.petStore.clearState()
    this.props.reasonStore.clearState()
  }

  init = (page = 1, limit = 10) => {
    this.props.petStore.getAll(page, limit)
    this.showProgressBar()
  }

  showProgressBar = async () => {
    const { isLoading } = this.props.petStore
    if(isLoading){
      const interval = setInterval(() => {
        this.setState({ progress: this.state.progress + 10 })
        if (this.state.progress >= 100 || !isLoading) clearInterval(interval);
      }, 20);
    }
  }
  
  handleViewInfo = (row) => {
    this.props.history.push('/pet-info', { id: row.original.id })
  }

  handleItemDelete = (reason) => {
    const data = {
      id: this.state.modalConfirm.id,
      reasonDelete: reason.reasonDelete
    }
    this.props.petStore.delete(data)
    this.toggleConfirm()
  }

  toggleConfirm = async (id) => {
    // if(!this.state.modalConfirm.open && this.state.modalConfirm.id == ""){
    //   await this.props.reasonStore.getAll()
    // }
    this.setState({ modalConfirm: { open: !this.state.modalConfirm.open, id: id || "" } });
  }

  handlePageSizeChange = (e) => {
    this.setState({ pageSize: e.target.value })
    this.init(this.state.page, e.target.value)
  }

  // setTimeoutAlert = () => {
  //   setTimeout(() => {
  //     this.setState({ modalFailure: { open: false } })
  //   }, 3000)
  // }

  getStatusClass(status) {
    const statusMap = {
      delete: "danger",
      active: "success"
    };

    return `text-${statusMap[status]}`
  }

  getStatusText(status) {
    const statusMap = {
      delete: "Removed",
      active: "Active"
    };

    return statusMap[status]
  }

  exportToCSV = async () => {
    const { petStore } = this.props
    await petStore.getReportHistory()
    this.refs.csv.link.click()
  }

  render(){
    const { t } = this.props
    const tableColumns = [
      {
        Header: t("Name"),
        accessor: "name",
        sortable: false,
        minWidth: 300
      },
      {
        Header: t("Breed"),
        accessor: "breed",
        sortable: false,
        minWidth: 300
      },
      {
        Header: t("Dimension"),
        accessor: "dimension",
        sortable: false,
        minWidth: 200
      },
      {
        Header: t("Status"),
        accessor: "status",
        sortable: false,
        minWidth: 100,
        Cell: row => (
          <span>
            {this.getStatusText(row.original.status)}
          </span>
        )
      },
      {
        Header: t("Actions"),
        minWidth: 180,
        sortable: false,
        Cell: row => (
          <ButtonGroup size="sm" className="d-table mx-auto">
            <Button theme="white" onClick={() => this.handleViewInfo(row)}>
              <i className="material-icons">&#xE870;</i>
            </Button>
            <Button theme="danger" onClick={() => this.toggleConfirm(row.original.id)} disabled={row.original.status !== "delete" ? false : true}>
              <i className="material-icons">&#xE872;</i>
            </Button>
          </ButtonGroup>
        )
      }
    ]

    const { datas, isLoading, totalPage, state: petState, error: petError, report } = this.props.petStore
    const data = toJS(datas)
    const dataTotalPage = toJS(totalPage)
    const state = toJS(petState)
    const error = toJS(petError)
    const openModalFailure = this.state.modalFailure.open
    const { datas: reasonDatas, state: reasonState } = this.props.reasonStore
    const rsState = toJS(reasonState)
    const dataReport = toJS(report)

    return(
      <div>
        <Container fluid className="px-0">
          <LoadingBar isLoading={isLoading} progress={this.state.progress} theme="primary" className="mb-0" />
          {
            (state.get === GET_ALL_PET.FAILURE || state.delete === DELETE_PET.FAILURE || rsState.get === GET_ALL_REASON.FAILURE) && openModalFailure && 
            <div>
              <ErrorAlert error={error} />
              {/* {openModalFailure && this.setTimeoutAlert()} */}
            </div>
          }
        </Container>
        <Container fluid className="main-content-container px-4">
          
          <Row noGutters className="page-header py-4">
            <PageTitle sm="4" title={t('Pets')} className="text-sm-left mb-3" />
            <Col sm="2" className="d-flex ml-auto my-auto">
              <Button className="mx-auto ml-sm-auto mr-sm-0" theme="primary" onClick={() => this.exportToCSV()}>
              <i className="material-icons">import_export</i> {t('Export Data')}
              </Button>
              <CSVLink 
                ref="csv"
                data={dataReport}
                filename={"Pet.csv"}
                style={{ display: 'none' }}
              />
            </Col>
          </Row>

          <Card className="p-0" style={{ marginBottom: 30 }}>
            <CardHeader className="p-0">
              <Container fluid className="file-manager__filters border-bottom">
                <Row>
                  {/* Filters :: Page Size */}
                  <Col className="file-manager__filters__rows d-flex" md="6">
                    <span>{t('Show')}</span>
                    <FormSelect
                      size="sm"
                      value={this.state.pageSize}
                      onChange={(e) => this.handlePageSizeChange(e)}
                    >
                      {this.state.pageSizeOptions.map((size, idx) => (
                        <option key={idx} value={size}>
                          {size} rows
                        </option>
                      ))}
                    </FormSelect>
                  </Col>
                </Row>
              </Container>
            </CardHeader>
            <CardBody className="p-0">
              <div>
                <ReactTable
                  loading={isLoading}
                  columns={tableColumns}
                  pages={dataTotalPage}
                  data={data || []}
                  pageSize={this.state.pageSize}
                  minRows={0}
                  showPageSizeOptions={false}
                  noDataText={"No data found"}
                  resizable={false}
                  manual
                  onFetchData={(state, instance) => {
                    this.setState({ page: state.page+1 })
                    this.init(state.page +1, this.state.pageSize)
                  }}
                  nextText={t('Next')}
                  previousText={t('Previous')}
                />
              </div>
            </CardBody>
          </Card>

          {
            this.state.modalConfirm.open &&
            <ModalDeletePet
              confirmFnBtn={(reason) => this.handleItemDelete(reason)}
              calcelFnBtn={() => this.toggleConfirm()}
              open={this.state.modalConfirm.open}
            />
          }

        </Container>
      </div>
    )
  }
}

export default withTranslation()(Pets)
