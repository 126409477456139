import axios from 'axios';
import {  observable, runInAction, action } from 'mobx';
import * as R from 'ramda'
import { path, v1 } from '../utils/api';
import buildActionTypes from '../utils/buildAction';
import StateHandling from '../utils/stateHandling'

export const GET_ALL_USER = buildActionTypes("GET_ALL_USER")
export const GET_USER = buildActionTypes("GET_USER")
export const GET_USER_REQUEST_DELETE = buildActionTypes("GET_USER_REQUEST_DELETE")
export const CONFIRM_DELETE = buildActionTypes("CONFIRM_DELETE")
export const CHANGE_PASSWORD = buildActionTypes("CHANGE_PASSWORD")

const initialState = {
  "get": null,
  "confirmDelete": null,
  "changePassword": null
}

const initialData = {
  id: "",
  email: "",
  createDate: "",
  updateDate: "",
  active: false,
  isConfirm: false,
  role: ""
}

class UserStore extends StateHandling {
  @observable state = initialState
  @observable data = initialData
  @observable datas = []
  @observable error = null
  @observable isLoading = false
  @observable result = false
  @observable totalPage = 0

  @action clearState = () => {
    if (!R.isNil(this.state)) { this.state = initialState }
  }

  @action clearData = () => {
    if (!R.isNil(this.data)) {
      this.data = initialData
    }
    if (!R.isNil(this.datas)) {
      this.datas = []
    }
    this.totalPage = 0
  }

  @action getAll = async (page = 0, limit = 0, filter = '') => {
    try {
      runInAction(() => { 
        this.state.get = GET_ALL_USER.REQUEST
        this.isLoading = true
      })
      const response = await axios({
        method: "get",
        url: `${path}/${v1}/users?page=${page}&limit=${limit}${filter !== ""? `&${filter}` : ""}`,
      })
      runInAction(() => {
        this.state.get = GET_ALL_USER.SUCCESS
        this.isLoading = false
        this.datas = response.data.data
        this.totalPage = response.data.totalPage
      })
    } catch (error) {
      runInAction(() => {
        this.state.get = GET_ALL_USER.FAILURE
        this.isLoading = false
        this.error = error
      })
    }
  }

  @action getById = async (id) => {
    try {
      runInAction(() => { 
        this.state.get = GET_USER.REQUEST
        this.isLoading = true
      })
      const response = await axios({
        method: "get",
        url: `${path}/${v1}/users/${id}`,
      })
      runInAction(() => {
        this.state.get = GET_USER.SUCCESS
        this.isLoading = false
        this.data = response.data.data
      })
    } catch (error) {
      runInAction(() => {
        this.state.get = GET_USER.FAILURE
        this.isLoading = false
        this.error = error
      })
    }
  }

  @action confirmDelete = async (data) => {
    try {
      runInAction(() => { 
        this.state.confirmDelete = CONFIRM_DELETE.REQUEST
        this.isLoading = true
      })
      const response = await axios({
        method: "post",
        url: `${path}/${v1}/users/delete/confirm`,
        data
      })
      runInAction(() => {
        this.state.confirmDelete = CONFIRM_DELETE.SUCCESS
        this.isLoading = false
        this.result = response.data.data
      })
    } catch (error) {
      runInAction(() => {
        this.state.confirmDelete = CONFIRM_DELETE.FAILURE
        this.isLoading = false
        this.error = error
      })
    }
  }

  @action changePassword = async (data) => {
    try {
      runInAction(() => { 
        this.state.changePassword = CHANGE_PASSWORD.REQUEST
        this.isLoading = true
      })
      await axios({
        method: "patch",
        url: `${path}/${v1}/users/change-pass`,
        headers: {
          'Content-Type': 'application/json'
        },
        data
      })
      runInAction(() => {
        this.state.changePassword = CHANGE_PASSWORD.SUCCESS
        this.isLoading = false
      })
    } catch (error) {
      runInAction(() => {
        this.state.changePassword = CHANGE_PASSWORD.FAILURE
        this.isLoading = false
        this.error = error.response.data.error
      })
    }
  }

}

export default UserStore
