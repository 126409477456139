import React, { Component } from "react";
import ReactTable from "react-table";
import { Container, Row, Card, CardBody, Button, CardHeader, ButtonGroup, FormSelect, Col } from "shards-react";
import { inject, observer } from "mobx-react";
import { toJS } from 'mobx';
import PageTitle from "../../components/common/PageTitle";
import LoadingBar from '../../components/common/LoadingBar';
import { ModalPop } from "../../components/common/MadalPop";
import moment from 'moment';
import { GET_ALL_USER, CONFIRM_DELETE } from "../../stores/UserStore";
import ErrorAlert from "../../components/alert/ErrorAlert";
import * as R from 'ramda';
import { withTranslation } from 'react-i18next';

@inject('userStore')
@observer
class TableUser extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 10,
      progress: 0,
      filterOptions: [{
        text: 'pending for delete',
        value: 'active=false'
      }],
      modalConfirm: {
        open: false,
        id: ""
      },
      modalFailure: {
        open: true
      },
      filterDefaultValue: "",
      page: 1
    }
  }

  componentDidMount(){
    this.init(this.state.page, this.state.pageSize)
  }

  componentDidUpdate(){
    const { state, clearState } = this.props.userStore
    const st = toJS(state)

    if(st.confirmDelete === CONFIRM_DELETE.SUCCESS){
      clearState()
      this.init(this.state.page, this.state.pageSize)
    } 
  }

  init = async (page = 1, limit = 10) => {
    const { filter } = this.props
    let conditions = ""
    if(this.state.filterDefaultValue === ""){ //first time
      if(filter === "pending-delete-user"){
        conditions = "active=false"
        this.setState({ filterDefaultValue: conditions })
      }else{
        this.setState({ filterDefaultValue: "all" })
      }
    }else{
      conditions = (this.state.filterDefaultValue === "all")? "" : this.state.filterDefaultValue
    }

    await this.props.userStore.getAll(page, limit, conditions)
    this.showProgressBar()
  }

  showProgressBar = async () => {
    const { isLoading } = this.props.userStore
    if(isLoading){
      const interval = setInterval(() => {
        this.setState({ progress: this.state.progress + 20 })
        if (this.state.progress >= 100 || !isLoading) clearInterval(interval);
      }, 20);
    }
  }

  handlePageSizeChange = (e) => {
    this.setState({ pageSize: e.target.value })
    this.init(this.state.page, e.target.value)
  }

  handleFilterChange = (e) => {
    var filter = ''
    const value = e.target.value
    if(value !== ""){
      filter = value
    }
    this.props.userStore.getAll(this.state.page, this.state.pageSize, filter)
    this.setState({ filterDefaultValue: value })
  }
  
  toggleConfirm = (id) => {
    this.setState({ modalConfirm: { open: !this.state.modalConfirm.open, id: id || "" } });
  }

  handleItemConfirmDelete = (id, state) => {
    const data = {
      userId: id,
      state: state
    }

    this.props.userStore.confirmDelete(data)
    this.toggleConfirm()
  }

  handleViewInfo = (row) => {
    this.props.history.push('/user-info', { id: row.original.id })
  }

  render(){
    const { title, t } = this.props
    const tableColumns = [
      {
        Header: t("Email"),
        sortable: false,
        accessor: "email",
        minWidth: 300
      },
      {
        Header: t("Created Date"),
        sortable: false,
        accessor: "createDate",
        minWidth: 200,
        Cell: row => (
          <span>{moment(row.original.createDate).format('DD MMM YYYY')}</span>
        )
      },
      {
        Header: t("Status"),
        sortable: false,
        minWidth: 100,
        Cell: row => (
          <span>{row.original.active? 'Active' : 'Pending delete'}</span>
        )
      },
      {
        Header: t("Actions"),
        sortable: false,
        minWidth: 100,
        Cell: row => (
          <ButtonGroup size="sm" className="d-table mx-auto">
            <Button theme="white" onClick={() => this.handleViewInfo(row)}>
              <i className="material-icons">&#xE870;</i>
            </Button>
            {/* {
              !row.original.active && */}
              <Button theme="danger" onClick={() => this.toggleConfirm(row.original.id)}>
                <i className="material-icons">&#xE872;</i>
              </Button>
            {/* } */}
            {/* <Button theme="danger" onClick={() => this.toggleConfirm(row.original.id)}>
              <i className="material-icons">&#xE872;</i>
            </Button> */}
          </ButtonGroup>
        )
      }
    ]

    const { datas, isLoading, state: userState, error: userError, totalPage } = this.props.userStore
    let data = toJS(datas)
    const state = toJS(userState)
    const error = toJS(userError)
    const openModalFailure = this.state.modalFailure.open
    const email = localStorage.getItem("@email")
    const dataTotalPage = toJS(totalPage)

    if(!R.isNil(data)){
      data = data.filter(v => {
        return v.email !== email
      })
    }

    return(
      <div>
        <Container fluid className="px-0">
          <LoadingBar isLoading={isLoading} progress={this.state.progress} theme="primary" className="mb-0" />
          {
            state.get === GET_ALL_USER.FAILURE && openModalFailure && 
            <div>
              <ErrorAlert error={error} />
              {/* {openModalFailure && this.setTimeoutAlert()} */}
            </div>
          }
        </Container>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle sm="4" title={t(`${title}`)} className="text-sm-left mb-3" />
          </Row>

          <Card className="p-0" style={{ marginBottom: 30 }}>
            <CardHeader className="p-0">
              <Container fluid className="file-manager__filters border-bottom">
                <Row>
                  {/* Filters :: Page Size */}
                  <Col className="file-manager__filters__rows d-flex" md="6">
                    <span>{t('Show')}</span>
                    <FormSelect
                      size="sm"
                      value={this.state.pageSize}
                      onChange={this.handlePageSizeChange}
                    >
                      {this.state.pageSizeOptions.map((size, idx) => (
                        <option key={idx} value={size}>
                          {size} rows
                        </option>
                      ))}
                    </FormSelect>

                    <span style={{ marginLeft: 10 }}>{t('Filter')} :</span>
                    <FormSelect
                      size="sm"
                      onChange={this.handleFilterChange}
                      value={this.state.filterDefaultValue || ""}
                    >
                      <option key="-1" value="all">All</option>
                      {this.state.filterOptions.map((filter, idx) => (
                        <option key={idx} value={filter.value}>{filter.text}</option>
                      ))}
                    </FormSelect>
                  </Col>

                  {/* Filters :: Search */}
                  {/* <Col className="file-manager__filters__search d-flex text-right" md="6">
                    <InputGroup seamless size="sm" className="ml-auto">
                      <InputGroupAddon type="prepend">
                        <InputGroupText>
                          <i className="material-icons">search</i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput onChange={this.handleFilterSearch} />
                    </InputGroup>
                    <Button pill theme="danger" className="ml-auto">&#8635; waiting for delete</Button>
                  </Col> */}
                </Row>
              </Container>
              {/* <Button pill theme="danger">&#8635; waiting for delete</Button> */}
            </CardHeader>
            <CardBody className="p-0">
              <div>
                <ReactTable
                  loading={isLoading}
                  columns={tableColumns}
                  pages={dataTotalPage}
                  data={data || []}
                  pageSize={this.state.pageSize}
                  minRows={0}
                  showPageSizeOptions={false}
                  noDataText={"No data found"}
                  resizable={false}
                  previousText={t('Previous')}
                  nextText={t('Next')}
                  manual
                  onFetchData={(state, instance) => {
                    this.setState({ page: state.page+1 })
                    this.init(state.page +1, this.state.pageSize)
                  }}
                />
              </div>
            </CardBody>
          </Card>

          <ModalPop
            title="Are you sure?"
            text="You won't be able to revert this!"
            confirmTextBtn="Yes, delete it"
            confirmFnBtn={() => this.handleItemConfirmDelete(this.state.modalConfirm.id, 'accept')}
            calcelFnBtn={() => this.toggleConfirm()}
            open={this.state.modalConfirm.open}
          />
          
        </Container>
      </div>
    )
  }
}

export default withTranslation()(TableUser)
