import React, { Component } from "react";
import Slider from "react-slick";
import * as R from "ramda";

function SampleArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color: "black" }}
      onClick={onClick}
    />
  );
}

export default class SimpleSlider extends Component {
  render() {
    const { images } = this.props
    const settings = {
      dots: true,
      lazyLoad: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleArrow />,
      prevArrow: <SampleArrow />,
    };

    return (
      <div>
        <Slider {...settings}>
          {
            R.isEmpty(images) || R.isNil(images) ?
            <div>
              <img 
                src={require('../../images/default-image.jpg')}
                style={{ margin: 'auto', width: 500, height: 500 }}
              />
            </div>
            :
            images.map((image, idx) => {
              return (
                <div key={idx}>
                  <img 
                    src={image}
                    style={{ margin: 'auto', maxWidth: 690, maxHeight: 460 }}
                  />
                </div>
              )
            })
          }
        </Slider>
      </div>
    );
  }
}