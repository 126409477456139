/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormInput,
  Button
} from "shards-react";
import { observer,inject } from 'mobx-react'
import { Redirect } from "react-router-dom";
import { LOGIN } from "../stores/AuthStore";
import { toJS } from "mobx";
import LoadingBar from '../components/common/LoadingBar';
import { withTranslation } from 'react-i18next';

@inject('authStore')
@observer
class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      progress: 0
    }
  }

  showProgressBar = async () => {
    const { isLoading } = this.props.authStore
    if(isLoading){
      const interval = setInterval(() => {
        this.setState({ progress: this.state.progress + 10 })
        if (this.state.progress >= 100 || !isLoading) clearInterval(interval);
      }, 20);
    }
  }

  handleSubmitForm = async (e) => {
    e.preventDefault();
    const { email, password } = this.state
    this.props.authStore.login({ email, password })
    this.showProgressBar()
  }

  update = (name, e) => {
    this.props.authStore.clearError()
    this.setState({ [name]: e.target.value });
  }

  logout = e => {
    e.preventDefault()
    this.props.authStore.logout()
  }

  render(){
    const { error: authError, state, isLoading } = this.props.authStore
    const { login } = toJS(state)
    const error = toJS(authError)
    const { t } = this.props

    if(login === LOGIN.SUCCESS){
      return <Redirect to="/users" />
    }

    return(
      <Container fluid className="main-content-container h-100 px-4">
        <LoadingBar isLoading={isLoading} progress={this.state.progress} theme="primary" />
        <Row noGutters className="h-100">
          <Col lg="3" md="5" className="auth-form mx-auto my-auto">
            <Card>
              <CardBody>
                {/* Logo */}
                <img
                  className="auth-form__logo d-table mx-auto mb-3"
                  src={require("../images/shards-dashboards-logo.svg")}
                  alt="Petshelt"
                />

                {/* Title */}
                <h5 className="auth-form__title text-center mb-4">
                  {t('Access Your Account')}
                </h5>

                {
                  error != null &&
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <span style={{ color: 'red' }}>
                      {
                        error.response != null &&
                        error.response.data.error.message
                      }
                    </span>
                  </div>
                }

                {/* Form Fields */}
                <Form onSubmit={this.handleSubmitForm}>
                  <div>
                    <FormGroup>
                      <label htmlFor="exampleInputEmail">{t('Email')}</label>
                      <FormInput
                        type="email"
                        id="exampleInputEmail"
                        placeholder={t("Enter email")}
                        autoComplete="email"
                        onChange={(e) => this.update("email", e)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="exampleInputPassword1">{t('Password')}</label>
                      <FormInput
                        type="password"
                        id="exampleInputPassword1"
                        placeholder={t("Password")}
                        autoComplete="current-password"
                        onChange={(e) => this.update("password", e)}
                      />
                    </FormGroup>

                    <Button
                      pill
                      theme="accent"
                      className="d-table mx-auto"
                      type="submit"
                    >
                      {t('Login')}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
  
}

export default withTranslation()(Login)
