import React, { Component } from "react";
import ReactTable from "react-table";
import { Container, Row, Card, CardBody, Button, CardHeader, ButtonGroup, Col, FormSelect } from "shards-react";
import { inject, observer } from "mobx-react";
import { toJS } from 'mobx';
import PageTitle from "../../components/common/PageTitle";
import LoadingBar from '../../components/common/LoadingBar';
import { DELETE_SHELTER } from "../../stores/ShelterStore";
import { ModalPop } from "../../components/common/MadalPop";
import moment from 'moment';
import { CSVLink } from "react-csv";
import * as R from "ramda";
import { withTranslation } from 'react-i18next';

const csvData = [
  ["firstname", "lastname", "email"],
  ["Ahmed", "Tomi", "ah@smthing.co.com"],
  ["Raed", "Labes", "rl@smthing.co.com"],
  ["Yezzi", "Min l3b", "ymin@cocococo.com"]
];

@inject('shelterStore')
@observer
class Shelters extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 10,
      progress: 0,
      modalConfirm: {
        open: false,
        id: ""
      },
      page: 1
    }
  }

  componentDidMount(){
    this.init(this.state.page, this.state.pageSize)
  }

  componentDidUpdate(){
    const { state, clearState } = this.props.shelterStore
    const st = toJS(state)

    if(st.delete === DELETE_SHELTER.SUCCESS){
      clearState()
      this.init(this.state.page, this.state.pageSize)
    } 
  }

  init = (page = 1, limit = 10) => {
    this.props.shelterStore.getAll(page, limit)
    this.showProgressBar()
  }

  showProgressBar = async () => {
    const { isLoading } = this.props.shelterStore
    if(isLoading){
      const interval = setInterval(() => {
        this.setState({ progress: this.state.progress + 10 })
        if (this.state.progress >= 100 || !isLoading) clearInterval(interval);
      }, 20);
    }
  }
  
  handleViewInfo = (row) => {
    this.props.history.push('/shelter-info', { id: row.original.id })
  }

  handleItemDelete = (id) => {
    this.props.shelterStore.delete(id)
    this.toggleConfirm()
  }

  toggleConfirm = (id) => {
    this.setState({ modalConfirm: { open: !this.state.modalConfirm.open, id: id || "" } });
  }

  handlePageSizeChange = (e) => {
    this.setState({ pageSize: e.target.value })
    this.init(this.state.page, e.target.value)
  }

  exportToCSV = async () => {
    const { shelterStore } = this.props
    await shelterStore.getReportHistory()
    this.refs.csv.link.click()
  }

  render(){
    const { t } = this.props
    const tableColumns = [
      {
        Header: t("Shelter Name"),
        accessor: "shelterName",
        sortable: false,
        minWidth: 300
      },
      {
        Header: t("Main Contact"),
        sortable: false,
        minWidth: 300,
        Cell: row => (
          <span>{row.original.contactName} {row.original.contactSurName}</span>
        )
      },
      {
        Header: t("Contact Number"),
        accessor: "contactNumber",
        sortable: false,
        minWidth: 200
      },
      {
        Header: t("Email"),
        accessor: "email",
        className: "text-center",
        sortable: false,
        minWidth: 180,
        Cell: row => (
          <span>{R.isEmpty(row.original.email)? "-" : row.original.email}</span>
        )
      },
      {
        Header: t("Actions"),
        minWidth: 180,
        sortable: false,
        Cell: row => (
          <ButtonGroup size="sm" className="d-table mx-auto">
            <Button theme="white" onClick={() => this.handleViewInfo(row)}>
              <i className="material-icons">&#xE870;</i>
            </Button>
            <Button theme="danger" onClick={() => this.toggleConfirm(row.original.id)}>
              <i className="material-icons">&#xE872;</i>
            </Button>
          </ButtonGroup>
        )
      }
    ]

    const { datas, isLoading, totalPage, report } = this.props.shelterStore
    const data = toJS(datas)
    const dataTotalPage = toJS(totalPage)
    const dataReport = toJS(report)

    return(
      <div>
        <Container fluid className="px-0">
          <LoadingBar isLoading={isLoading} progress={this.state.progress} theme="primary" className="mb-0" />
        </Container>
        <Container fluid className="main-content-container px-4">
          
          <Row noGutters className="page-header py-4">
            <PageTitle sm="4" title={t('Shelters')} className="text-sm-left mb-3" />
            <Col sm="2" className="d-flex ml-auto my-auto">
              <Button className="mx-auto ml-sm-auto mr-sm-0" theme="primary" onClick={() => this.exportToCSV()}>
                <i className="material-icons">import_export</i> {t('Export Data')}
              </Button>
              <CSVLink 
                ref="csv"
                data={dataReport}
                filename={"Shelter.csv"}
                style={{ display: 'none' }}
              />
            </Col>
          </Row>

          <Card className="p-0" style={{ marginBottom: 30 }}>
            <CardHeader className="p-0">
              <Container fluid className="file-manager__filters border-bottom">
                <Row>
                  {/* Filters :: Page Size */}
                  <Col className="file-manager__filters__rows d-flex" md="6">
                    <span>{t('Show')}</span>
                    <FormSelect
                      size="sm"
                      value={this.state.pageSize}
                      onChange={(e) => this.handlePageSizeChange(e)}
                    >
                      {this.state.pageSizeOptions.map((size, idx) => (
                        <option key={idx} value={size}>
                          {size} rows
                        </option>
                      ))}
                    </FormSelect>
                  </Col>
                </Row>
              </Container>
            </CardHeader>
            <CardBody className="p-0">
              <div>
                <ReactTable
                  loading={isLoading}
                  columns={tableColumns}
                  pages={dataTotalPage}
                  data={data || []}
                  pageSize={this.state.pageSize}
                  minRows={0}
                  showPageSizeOptions={false}
                  noDataText={"No data found"}
                  resizable={false}
                  manual
                  onFetchData={(state, instance) => {
                    this.setState({ page: state.page+1 })
                    this.init(state.page +1, this.state.pageSize)
                  }}
                  nextText={t('Next')}
                  previousText={t('Previous')}
                />
              </div>
            </CardBody>
          </Card>

          <ModalPop
            title="Are you sure?"
            text="You won't be able to revert this!"
            confirmTextBtn="Yes, delete it"
            confirmFnBtn={() => this.handleItemDelete(this.state.modalConfirm.id)}
            calcelFnBtn={() => this.toggleConfirm()}
            open={this.state.modalConfirm.open}
          />

        </Container>
      </div>
    )
  }
}

export default withTranslation()(Shelters)
