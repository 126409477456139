import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormInput,
  Button
} from "shards-react";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { CHANGE_PASSWORD } from "../../stores/UserStore";
import { Formik } from 'formik';
import * as Yup from "yup";
import { withTranslation } from 'react-i18next';

const Schema = Yup.object().shape({
  password: Yup
    .string()
    .required("Password is required")
    .min(8, "Password require more than 8 characters"),
  confirmPassword: Yup
    .string()
    .when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      )
    })
});

@inject("userStore")
@observer
class ChangePassword extends Component{

  constructor(props) {
    super(props)
  }

  componentWillUnmount() {
    this.props.userStore.clearState()
  }

  render(){
    const { error, changePassword, state: userStoreState } = this.props.userStore
    const state = toJS(userStoreState)
    const { t } = this.props

    return(
      <Container fluid className="main-content-container h-100 px-4">
        <Row noGutters className="h-100" style={{ marginTop: 50 }}>
          <Col lg="3" md="5" className="auth-form mx-auto">
            <Card>
              <CardBody>
                {/* Logo */}
                <img
                  className="auth-form__logo d-table mx-auto mb-3"
                  src={require("../../images/shards-dashboards-logo.svg")}
                  alt="Shards Dashboards - Change Password Template"
                />
    
                {/* Title */}
                <h5 className="auth-form__title text-center mb-4">
                  {t("Change Password")}
                </h5>

                {
                  error != null &&
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <span style={{ color: 'red' }}>{error.message}</span>
                  </div>
                }

                {
                  state.changePassword === CHANGE_PASSWORD.SUCCESS &&
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <span style={{ color: 'green' }}>Change password successfully</span>
                  </div>
                }
    
                {/* Form Fields */}
                <Formik
                  initialValues={{ password: '', confirmPassword: '' }}
                  validationSchema={Schema}
                  onSubmit={async (values) => {
                    await changePassword({ password: values.password })
                  }}
                >
                  {({ handleChange, handleSubmit, errors }) => (
                    <div>
                      <form onSubmit={handleSubmit}>
                        <FormGroup>
                          <label>{t('Password')}</label>
                          <FormInput
                            type="password"
                            id="password"
                            placeholder={t('Password')}
                            autoComplete="new-password"
                            onChange={handleChange}
                          />
                          <div style={{ marginTop: 10, marginBottom: 10 }}>
                            <span style={{ color: 'red' }}>{errors.password}</span>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <label>{t('Confirm Password')}</label>
                          <FormInput
                            type="password"
                            id="confirmPassword"
                            placeholder={t('Confirm Password')}
                            autoComplete="new-password"
                            onChange={handleChange}
                          />
                          <div style={{ marginTop: 10, marginBottom: 10 }}>
                            <span style={{ color: 'red' }}>{errors.confirmPassword}</span>
                          </div>
                        </FormGroup>
                        <Button
                          pill
                          theme="accent"
                          className="d-table mx-auto"
                          type="submit"
                        >
                          {t('Change Password')}
                        </Button>
                      </form>
                    </div>
                  )}
                </Formik>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withTranslation()(ChangePassword)
