import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormCheckbox,
  Button,
  Badge
} from "shards-react";
import { toJS } from 'mobx';
import { observer, inject } from "mobx-react";
import * as R from "ramda";
import FormSectionTitle from "../../components/edit-user-profile/FormSectionTitle";
import PageTitle from "../../components/common/PageTitle";
import { DELETE_PET, GET_PET } from "../../stores/PetStore";
import SimpleSlider from "../../components/carousel/SimpleSlider";
import ErrorAlert from "../../components/alert/ErrorAlert";
import ModalDeletePet from "../../components/common/ModalDeletePet";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

@inject("petStore", "reasonStore", "shelterStore")
@observer
class PetInfo  extends Component {

  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      modalConfirm: {
        open: false
      },
      modalFailure: {
        open: true
      }
    }
  }

  componentDidMount() {
    this.init()
  }

  componentDidUpdate() {
    const { state: shelterState } = this.props.petStore
    const state = toJS(shelterState)
    if(state.delete === DELETE_PET.SUCCESS){
      this.goBack()
    }
  }

  componentWillUnmount() {
    this.props.petStore.clearState()
    this.props.petStore.clearData()
    this.props.reasonStore.clearState()
    this.props.reasonStore.clearData()
  }

  init = async () => {
    const { location: { state } } = this.props;
    const { id } = state;
    await this.props.petStore.getById(id)
    const { data: petData } = this.props.petStore
    const data = toJS(petData)
    await this.props.shelterStore.getById(data.shelterId)
  }

  handleItemDelete = (reason) => {
    const data = {
      id: this.props.location.state.id,
      reasonDelete: reason.reasonDelete
    }
    this.props.petStore.delete(data)
    this.toggleConfirm()
  }

  toggleConfirm = async () => {
    if(!this.state.modalConfirm.open){
      await this.props.reasonStore.getAll()
    }
    this.setState({ modalConfirm: { open: !this.state.modalConfirm.open } });
  }

  goBack = () => {
    this.props.history.goBack()
  }

  showTextValue = (value) => {
    return (R.isEmpty(value))? "-" : value
  }

  birthdateFormat = (day, month, year) => {
    var birthdate = (!R.isEmpty(day))? day : ''
    if(!R.isEmpty(month)){
      birthdate = birthdate.concat(' ', month)
    }
    if(!R.isEmpty(year)){
      birthdate = birthdate.concat(' ', year)
    }
    return birthdate
  }

  // setTimeoutAlert = () => {
  //   setTimeout(() => {
  //     this.setState({ modalFailure: { open: false } })
  //   }, 3000)
  // }

  getStatusText(status) {
    const statusMap = {
      delete: "Removed",
      active: "Active"
    };

    return statusMap[status]
  }

  getStatusClassBadge(status) {
    const statusMap = {
      delete: "danger",
      active: "success"
    };

    return statusMap[status]
  }

  getValueConditions(key) {
    const keyMap = {
      children: "Can have contact with children",
      chip: "Has a chip",
      otherPets: "Can have contact with other pets",
      vaccinated: "Has been vaccinated"
    }

    return keyMap[key]
  }

  render(){
    const { isLoading, data: petData, state: petState, error: petError } = this.props.petStore
    const openModalFailure = this.state.modalFailure.open

    if(isLoading){
      return null
    }

    const data = toJS(petData)
    const state = toJS(petState)
    const error = toJS(petError)
    const { datas: reasonDatas } = this.props.reasonStore
    const reasonData = toJS(reasonDatas)
    const { t } = this.props
    const { data: shelterStoreData } = this.props.shelterStore
    const shelterData = toJS(shelterStoreData)

    return(
      <div>
        <Container fluid className="px-0">
          {
            (state.get === GET_PET.FAILURE || state.delete === DELETE_PET.FAILURE) && openModalFailure && 
            <div>
              <ErrorAlert error={error} />
              {/* {openModalFailure && this.setTimeoutAlert()} */}
            </div>
          }
        </Container>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle title={t("PetDetail")} className="text-sm-left mb-3" />
            <Col sm="2" className="d-flex ml-auto my-auto">
              <Button className="mx-auto ml-sm-auto mr-sm-0" theme="primary" onClick={() => this.goBack()}>
                &larr; {t("Go Back")}
              </Button>
              <Button className="mx-auto ml-sm-auto mr-sm-0" theme="danger" onClick={() => this.toggleConfirm()}>
              {t("Delete")}
              </Button>
            </Col>
          </Row>

          <Row>
            <Col lg="8" className="mx-auto mt-4">
              <SimpleSlider images={data.photoImgUrls} />
            </Col>
          </Row>
          
          <Row style={{ marginTop: 30, }}>
            <Col lg="8" className="mx-auto mt-4">
              <Card small className="edit-user-details mb-4">

                <CardBody className="p-0">
                  <Form className="py-4" onSubmit={this.handleFormSubmit}>
                    <FormSectionTitle
                      title={t("Basic Information")}
                      description=""
                    />

                    <Row form className="mx-4">
                      <Col lg="12">
                        <Row form>
                          <Col md="6" className="form-group">
                            <label htmlFor="contactName">{t("Name")}:</label>
                            <p>{this.showTextValue(data.name)}</p>
                          </Col>

                          <Col md="6" className="form-group">
                            <label htmlFor="contactSurName">{t("Breed")}:</label>
                            <p>{this.showTextValue(data.breed)}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg="12">
                        <Row form>
                          <Col md="6" className="form-group">
                            <label htmlFor="contactName">{t("Dimension")}:</label>
                            <p>{this.showTextValue(data.dimension)}</p>
                          </Col>

                          <Col md="6" className="form-group">
                            <label htmlFor="contactSurName">{t("Age")}:</label>
                            <p>{this.showTextValue(data.ageOfPet)}</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row form className="mx-4">
                      <Col lg="12">
                        <Row form>
                          <Col md="6" className="form-group">
                            <label htmlFor="contactName">{t("Department")}:</label>
                            <p>{this.showTextValue(shelterData.department)}</p>
                          </Col>

                          <Col md="6" className="form-group">
                            <label htmlFor="contactSurName">{t("Shelter Name")}:</label>
                            {
                              R.isEmpty(data.shelterId) ? "-" :
                              <div>{this.showTextValue(shelterData.shelterName)} (<Link to={{ pathname: "/shelter-info", state: { id: data.shelterId } }} >Link</Link>)</div>
                            }
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row form className="mx-4">
                      <Col lg="12">
                        <Row form>
                          <Col md="4" className="form-group">
                            <label htmlFor="contactName">{t("Status")}:</label>
                            <p>
                              <Badge pill theme={this.getStatusClassBadge(data.status)}>
                                {this.getStatusText(data.status)}
                              </Badge>
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <hr />

                    <FormSectionTitle
                      title={t("About")}
                      description=""
                    />

                    <Row form className="mx-4">
                      <Col md="12" className="form-group">
                        <label htmlFor="shelterName">{t("Description")}:</label>
                        <p>{this.showTextValue(data.description)}</p>
                      </Col>
                    </Row>

                    <Row form className="mx-4">
                      <Col md="12" className="form-group">
                        <label htmlFor="address">{t("Gender")}:</label>
                        <p>{this.showTextValue(data.gender)}</p>
                      </Col>
                    </Row>

                    <Row form className="mx-4">
                      <Col md="12" className="form-group">
                        <label htmlFor="description">{t("Conditions")}:</label>
                        <fieldset>
                        {
                          R.isEmpty(data.conditions) ?
                          <div>-</div>
                          :
                          <div style={{ marginLeft: 20 }}>
                            {
                              Object.entries(data.conditions).map(([key,value]) => {
                                const condition = t(`${this.getValueConditions(key)}`)
                                if(value){
                                  return <FormCheckbox key={key} defaultChecked disabled>{condition}</FormCheckbox>
                                }else{
                                  return <FormCheckbox key={key} disabled>{condition}</FormCheckbox>
                                }
                              })
                            }
                          </div>
                        }
                        </fieldset>
                      </Col>
                    </Row>

                    <Row form className="mx-4">
                      <Col md="12" className="form-group">
                        <label htmlFor="description">{t("Health")}:</label>
                        <p>{this.showTextValue(data.health)}</p>
                      </Col>
                    </Row>

                  </Form>
                </CardBody>
              </Card>
            </Col>

            {/* <Col lg="4" className="mx-auto mt-4">
              <Row>
                <Col lg="12" className="text-center mt-12">
                  <Slider {...settings}>
                    <div>
                      <img 
                        src={require('../../images/default-image.jpg')} 
                        width="100%"
                        height="400"
                        style={Styles.images}
                      />
                    </div>
                  </Slider>
                </Col>
              </Row>
            </Col> */}
          </Row>

        </Container>

        <ModalDeletePet
          confirmFnBtn={(reason) => this.handleItemDelete(reason)}
          calcelFnBtn={() => this.toggleConfirm()}
          open={this.state.modalConfirm.open}
          data={reasonData}
        />
      </div>
    )
  }
}

const Styles = {
  images: {
    borderTopLeftRadius: "0.625rem", 
    borderTopRightRadius: "0.625rem", 
    borderBottomRightRadius: "0.625rem", 
    borderBottomLeftRadius: "0.625rem" 
  },
}

export default withTranslation()(PetInfo)