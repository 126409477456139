import React, { Component } from "react";
import { toJS } from 'mobx';
import { observer, inject } from "mobx-react";
import { Button, Modal, ModalBody, FormRadio, Row, Col } from "shards-react";
import { Formik } from 'formik';
import * as Yup from "yup";
import { withTranslation } from 'react-i18next';

const Schema = Yup.object().shape({
  reasonDelete: Yup
    .string()
    .required("Reason is required")
});

@inject("reasonStore")
@observer
class ModalDeletePet extends Component {

  componentDidMount() {
    this.init()
  }

  componentWillUnmount() {
    this.props.reasonStore.clearState()
    this.props.reasonStore.clearData()
  }

  init = async () => {
    await this.props.reasonStore.getAll()
  }

// export const ModalDeletePet = ({ confirmFnBtn, calcelFnBtn, open, data }) => {
  render(){
    const { confirmFnBtn, calcelFnBtn, open, t } = this.props
    const { datas: reasonDatas } = this.props.reasonStore
    const data = toJS(reasonDatas)

    return (
      <Modal open={open} toggle={() => calcelFnBtn()} >
        <ModalBody className="text-center">
          <h4>{t('Why do you want to delete this record?')}</h4>
          <div>
            <Formik
              initialValues={{ reasonDelete: '' }}
              validationSchema={Schema}
              onSubmit={async (values) => {
                confirmFnBtn(values)
              }}
            >
              {({ handleChange, handleSubmit, errors }) => (
                <Row>
                  <Col sm="12" md="12" className="mx-auto text-left" style={{ marginTop: 15 }}>
                    {
                      data &&
                      data.map((r, idx) => {
                        return(
                          <div className="custom-control custom-radio" style={{ margin: 5 }} key={idx}>
                            <input
                              type="radio"
                              className="custom-control-input"
                              id={`radio-reason-${idx}`}
                              value={r.text}
                              name="reasonDelete"
                              onChange={handleChange}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`radio-reason-${idx}`}
                            >
                              {r.text}
                            </label>
                          </div>
                        )
                      })
                    }
                    <div style={{ marginTop: 5, marginBottom: 5, marginLeft: 5 }}>
                      <span style={{ color: 'red' }}>{errors.reasonDelete}</span>
                    </div>
                  </Col>
                  <Col style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: 15 }}>
                    <Button onClick={() => handleSubmit()} style={{ width: '50%', margin: 2 }}>{t('Delete')}</Button>
                    <Button theme="danger" onClick={() => calcelFnBtn()} style={{ width: '50%', margin: 2 }}>{t('Cancel')}</Button>
                  </Col>
                </Row>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

export default withTranslation()(ModalDeletePet) 