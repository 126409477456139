import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormInput,
  FormTextarea,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Button,
  Badge
} from "shards-react";
import { Link } from "react-router-dom";
import { toJS } from 'mobx';
import { observer, inject } from "mobx-react";
import * as R from "ramda";
import FormSectionTitle from "../../components/edit-user-profile/FormSectionTitle";
import PageTitle from "../../components/common/PageTitle";
import { DELETE_SHELTER } from "../../stores/ShelterStore";
import { ModalPop } from "../../components/common/MadalPop";
import SectionTitle from "../../components/file-manager-cards/SectionTitle";
import DocumentsList from "../../components/file-manager-cards/DocumentsList";
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';

function PetList(props) {
  const { datas } = props;
  return (
    <div>
      {
        datas &&
        <Row>
        {
          datas.map((post, idx) => {
            const image = (!R.isEmpty(post.photoImgUrls) && !R.isNil(post.photoImgUrls))? post.photoImgUrls[0] : require('../../images/default-image.jpg')
            return (
              <Col lg="3" md="6" sm="12" className="mb-4" key={idx}>
                <Card small className="card-post card-post--1">
                  <div
                    className="card-post__image"
                    style={{ backgroundImage: `url(${image})` }}
                  >
                  </div>
                  <CardBody>
                    <h5 className="card-title">
                      <span className="text-fiord-blue"><Link to={{ pathname: '/pet-info', state: { id: post.id } }} >{post.name}</Link></span>
                    </h5>
                    <p className="card-text d-inline-block mb-3">{post.breed}</p>
                    <p className="text-muted">{post.dimension}</p>
                  </CardBody>
                </Card>
              </Col>
            )
          })
        }
        </Row>
      }
    </div>
  );
}

@inject("shelterStore", "petStore", "authStore")
@observer
class ShelterInfo  extends Component {

  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      modalConfirm: {
        open: false
      }
    }
  }

  componentDidMount() {
    this.init()
  }

  componentDidUpdate() {
    const { state: shelterState } = this.props.shelterStore
    const state = toJS(shelterState)
    if(state.delete === DELETE_SHELTER.SUCCESS){
      this.goBack()
    }
  }

  componentWillUnmount() {
    this.props.shelterStore.clearState()
  }

  init = async () => {
    const { location: { state }, shelterStore, petStore } = this.props
    const { id } = state
    shelterStore.getById(id)
    petStore.getByShelterId(id)
  }

  getHours = ({ startTime, endTime }) => {
    let openHours = ""
    const txtClosed = i18n.t("Closed")
    if(R.isEmpty(startTime)){
      return txtClosed
    }else{
      openHours = startTime
    }
    if(R.isEmpty(endTime)){
      openHours += ` - ${txtClosed}`
    }else{
      openHours += ` - ${endTime}`
    }
    return openHours
  }

  handleItemDelete = (id) => {
    this.toggleConfirm()
    this.props.shelterStore.delete(id)
  }

  toggleConfirm = () => {
    this.setState({ modalConfirm: { open: !this.state.modalConfirm.open } });
  }

  goBack = () => {
    this.props.history.goBack()
  }

  render(){
    const { disabled } = this.state
    const { shelterStore, petStore, t } = this.props
    const { isLoading, data: shelterData } = shelterStore
    const { datas: petDatas } = petStore
    const pets = toJS(petDatas)

    const numOfPet = (!R.isNil(pets))? pets.length : 0

    if(isLoading){
      return null
    }

    const data = toJS(shelterData)
    const oph = data.openingHours

    return(
      <div>
        <Container fluid className="px-0">
        </Container>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle title={t("Shelter Detail")} className="text-sm-left mb-3" />
            <Col sm="2" className="d-flex ml-auto my-auto">
              <Button className="mx-auto ml-sm-auto mr-sm-0" theme="primary" onClick={() => this.goBack()}>
                &larr; {t("Go Back")}
              </Button>
              <Button className="mx-auto ml-sm-auto mr-sm-0" theme="danger" onClick={() => this.toggleConfirm()}>
                {t('Delete')}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col lg="8" className="mx-auto mt-4">
              <Card small className="edit-user-details mb-4">

                <CardBody className="p-0">
                  <Form className="py-4">
                    <FormSectionTitle
                      title={t("Main Contact")}
                      description=""
                    />

                    <Row form className="mx-4">
                      <Col lg="12">
                        <Row form>
                          <Col md="6" className="form-group">
                            <label htmlFor="contactName">{t('Name')}:</label>
                            <p>{data.contactName}</p>
                          </Col>

                          <Col md="6" className="form-group">
                            <label htmlFor="contactSurName">{t('Surname')}:</label>
                            <p>{data.contactSurname}</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <hr />

                    <FormSectionTitle
                      title={t("Shelter Information")}
                      description=""
                    />

                    <Row form className="mx-4">
                      <Col md="4" className="form-group">
                        <label htmlFor="shelterName">{t("Shelter Name")}:</label>
                        <p>{data.shelterName}</p>
                      </Col>

                      <Col md="4" className="form-group">
                        <label htmlFor="contactNumber">{t("Contact Number")}:</label>
                        <p>{data.contactNumber}</p>
                      </Col>

                      <Col md="4" className="form-group">
                        <label htmlFor="website">{t("Website")}:</label>
                        <p>{data.website}</p>
                      </Col>
                    </Row>

                    <Row form className="mx-4">
                      <Col md="8" className="form-group">
                        <label htmlFor="address">{t("Address")}:</label>
                        <p>{data.address}</p>
                      </Col>
                      <Col md="4" className="form-group">
                        <label htmlFor="address">{t("PostalCode")}:</label>
                        <p>{R.isEmpty(data.postalCode)? '-' : data.postalCode}</p>
                      </Col>
                    </Row>

                    <Row form className="mx-4">
                      <Col md="12" className="form-group">
                        <label htmlFor="description">{t("Description")}:</label>
                        <p>{data.description}</p>
                      </Col>
                    </Row>

                    <Row form className="mx-4">
                      <Col md="6" className="form-group">
                        <label htmlFor="description">{t("Department")}:</label>
                        <p>{data.department}</p>
                      </Col>

                      <Col md="6" className="form-group">
                        <label htmlFor="description">{t("Email")}:</label>
                        <div>
                          {
                            R.isEmpty(data.email) ? <p>-</p> :
                            <p>{data.email} (<Link to={{ pathname: "/user-info", state: { id: data.userId} }} >Link</Link>)</p>
                          }
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>

              <div className="file-manager file-manager-cards">
                <SectionTitle title={`${t("Pet list")} (${numOfPet})`} />
                {/* <DocumentsList /> */}
                <PetList datas={pets} />
              </div>

              
            </Col>

            <Col lg="4" className="mx-auto mt-4">
              <Row>
                <Col lg="12" className="text-center mt-12">
                  {
                    data.photoUrl !== '' ?
                    <img
                      src={data.photoUrl}
                      width="100%"
                      style={Styles.images, { maxHeight: 300 }}
                    />
                    :
                    <img 
                      src={require('../../images/default-image.jpg')} 
                      width="100%"
                      style={Styles.images, { maxHeight: 300 }}
                    />
                  }
                </Col>
              </Row>
              <br/>
              <Row>
                <Col lg="12" className="text-center mt-12">
                  <Card small className="mb-4">
                    <CardHeader className="border-bottom">
                      <h6 className="m-0">{t("Operating Hours")}</h6>
                    </CardHeader>

                    <CardBody>
                      <ListGroup small flush className="list-group-small">
                        <ListGroupItem className="d-flex px-3">
                          <span className="text-semibold text-fiord-blue">{t("Sunday")}</span>
                          <span className="ml-auto text-right text-semibold text-reagent-gray">
                            {this.getHours(oph.sun)}
                          </span>
                        </ListGroupItem>
                        <ListGroupItem className="d-flex px-3">
                          <span className="text-semibold text-fiord-blue">{t("Monday")}</span>
                          <span className="ml-auto text-right text-semibold text-reagent-gray">
                          {this.getHours(oph.mon)}
                          </span>
                        </ListGroupItem>
                        <ListGroupItem className="d-flex px-3">
                          <span className="text-semibold text-fiord-blue">{t("Tuesday")}</span>
                          <span className="ml-auto text-right text-semibold text-reagent-gray">
                            {this.getHours(oph.tue)}
                          </span>
                        </ListGroupItem>
                        <ListGroupItem className="d-flex px-3">
                          <span className="text-semibold text-fiord-blue">{t("Wednesday")}</span>
                          <span className="ml-auto text-right text-semibold text-reagent-gray">
                            {this.getHours(oph.wed)}
                          </span>
                        </ListGroupItem>
                        <ListGroupItem className="d-flex px-3">
                          <span className="text-semibold text-fiord-blue">{t("Thursday")}</span>
                          <span className="ml-auto text-right text-semibold text-reagent-gray">
                            {this.getHours(oph.thu)}
                          </span>
                        </ListGroupItem>
                        <ListGroupItem className="d-flex px-3">
                          <span className="text-semibold text-fiord-blue">{t("Friday")}</span>
                          <span className="ml-auto text-right text-semibold text-reagent-gray">
                            {this.getHours(oph.fri)}
                          </span>
                        </ListGroupItem>
                        <ListGroupItem className="d-flex px-3">
                          <span className="text-semibold text-fiord-blue">{t("Saturday")}</span>
                          <span className="ml-auto text-right text-semibold text-reagent-gray">
                          {this.getHours(oph.sat)}
                          </span>
                        </ListGroupItem>
                      </ListGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

        </Container>

        <ModalPop
            title="Are you sure?"
            text="You won't be able to revert this!"
            confirmTextBtn="Yes, delete it"
            confirmFnBtn={() => this.handleItemDelete(data.id)}
            calcelFnBtn={() => this.toggleConfirm()}
            open={this.state.modalConfirm.open}
          />
      </div>
    )
  }
}

const Styles = {
  images: {
    borderTopLeftRadius: "0.625rem", 
    borderTopRightRadius: "0.625rem", 
    borderBottomRightRadius: "0.625rem", 
    borderBottomLeftRadius: "0.625rem" 
  },
}

export default withTranslation()(ShelterInfo)