import ShelterStore from './ShelterStore';
import AuthStore from './AuthStore';
import UserStore from './UserStore';
import PetStore from './PetStore';
import ReasonStore from './ReasonStore';

export default {
  shelterStore: new ShelterStore(),
  authStore: new AuthStore(),
  userStore: new UserStore(),
  petStore: new PetStore(),
  reasonStore: new ReasonStore()
}