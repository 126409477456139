import axios from 'axios';
import {  observable, runInAction, action } from 'mobx';
import * as R from 'ramda'
import { path, v1 } from '../utils/api';
import buildActionTypes from '../utils/buildAction';
import StateHandling from '../utils/stateHandling'

export const GET_ALL_REASON = buildActionTypes("GET_ALL_REASON")
export const CREATE_REASON = buildActionTypes("CREATE_REASON")
export const DELETE_REASON = buildActionTypes("DELETE_REASON")

const initialState = {
  "get": null,
  "create": null,
  "delete": null
}

const initialData = {
  id: "",
  text: ""
}

class ReasonStore extends StateHandling {
  @observable state = initialState
  @observable data = initialData
  @observable datas = []
  @observable error = null
  @observable isLoading = false
  @observable totalPage = 0

  @action clearState = () => {
    if (!R.isNil(this.state)) { this.state = initialState }
  }

  @action clearData = () => {
    if (!R.isNil(this.data)) {
      this.data = initialData
    }
    if (!R.isNil(this.datas)) {
      this.datas = []
    }
    this.totalPage = 0
  }

  @action getAll = async () => {
    try {
      runInAction(() => { 
        this.state.get = GET_ALL_REASON.REQUEST
        this.isLoading = true
      })
      const response = await axios({
        method: "get",
        url: `${path}/${v1}/reasons`,
      })
      runInAction(() => {
        this.state.get = GET_ALL_REASON.SUCCESS
        this.isLoading = false
        this.datas = response.data.data
        this.totalPage = response.data.totalPage
      })
    } catch (error) {
      runInAction(() => {
        this.state.get = GET_ALL_REASON.FAILURE
        this.isLoading = false
        this.error = error
      })
    }
  }

  @action create = async (data) => {
    try {
      runInAction(() => { 
        this.state.create = CREATE_REASON.REQUEST
        this.isLoading = true
      })
      const response = await axios({
        method: "post",
        url: `${path}/${v1}/reasons`,
        data
      })
      runInAction(() => {
        this.state.create = CREATE_REASON.SUCCESS
        this.isLoading = false
      })
    } catch (error) {
      runInAction(() => {
        this.state.create = CREATE_REASON.FAILURE
        this.isLoading = false
        this.error = error
      })
    }
  }

  @action delete = async (id) => {
    try {
      runInAction(() => { 
        this.state.delete = DELETE_REASON.REQUEST
        this.isLoading = true
      })
      const response = await axios({
        method: "delete",
        url: `${path}/${v1}/reasons/${id}`
      })
      runInAction(() => {
        this.state.delete = DELETE_REASON.SUCCESS
        this.isLoading = false
      })
    } catch (error) {
      runInAction(() => {
        this.state.delete = DELETE_REASON.FAILURE
        this.isLoading = false
        this.error = error
      })
    }
  }

}

export default ReasonStore
