import React from "react";
import { Button, Modal, ModalBody, FormRadio, Row, Col, FormGroup, FormInput } from "shards-react";
import { Formik } from 'formik';
import * as Yup from "yup";
import i18n from '../../i18n';

const Schema = Yup.object().shape({
  reason: Yup
    .string()
    .required("Reason is required")
});

export const ModalAddReason = ({ confirmFnBtn, calcelFnBtn, open }) => {
  return (
    <Modal open={open} toggle={() => calcelFnBtn()} >
      <ModalBody className="text-left">
        <h4>{i18n.t("Add Reason")}</h4>
        <div>
          <Formik
            initialValues={{ reason: '' }}
            validationSchema={Schema}
            onSubmit={async (values) => {
              confirmFnBtn({ text: values.reason })
            }}
          >
            {({ handleChange, handleSubmit, errors }) => (
              <Row>
                <Col sm="12" md="12" className="mx-auto text-left" style={{ marginTop: 15 }}>
                  <FormGroup>
                    {/* <label>Reason: </label> */}
                    <FormInput
                      type="text"
                      id="reason"
                      placeholder={i18n.t("reason")}
                      autoComplete="reason"
                      onChange={handleChange}
                    />
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                      <span style={{ color: 'red' }}>{errors.reason}</span>
                    </div>
                  </FormGroup>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: 15 }}>
                  <Button onClick={() => handleSubmit()} style={{ width: '50%', margin: 2 }}>{i18n.t('Add')}</Button>
                </Col>
              </Row>
            )}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  )
}