import React, { Component } from "react";
import TableUser from "./TableUser";

class Users extends Component {
  render(){
    return(
      <div>
        <TableUser filter="all" title="Users" {...this.props} />
      </div>
    )
  }
}

export default Users
