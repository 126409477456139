import React, { Component } from "react";
import TableUser from "./TableUser";

class PendingDeleteUser extends Component {
  render(){
    return(
      <div>
        <TableUser filter="pending-delete-user" title="Pending Delete" {...this.props}/>
      </div>
    )
  }
}

export default PendingDeleteUser
