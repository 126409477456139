import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Button,
  Badge
} from "shards-react";
import { toJS } from 'mobx';
import { observer, inject } from "mobx-react";
import * as R from "ramda";
import FormSectionTitle from "../../components/edit-user-profile/FormSectionTitle";
import PageTitle from "../../components/common/PageTitle";
import moment from 'moment';
import { GET_USER } from "../../stores/UserStore";
import ErrorAlert from "../../components/alert/ErrorAlert";
import { withTranslation } from 'react-i18next';

@inject("userStore")
@observer
class UserInfo  extends Component {

  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      modalConfirm: {
        open: false
      },
      modalFailure: {
        open: true
      }
    }
  }

  componentDidMount() {
    this.init()
  }

  componentWillUnmount() {
    this.props.userStore.clearState()
    this.props.userStore.clearData()
  }

  init = async () => {
    const { location: { state } } = this.props;
    const { id } = state;
    await this.props.userStore.getById(id)
  }

  goBack = () => {
    this.props.history.goBack()
  }

  showTextValue = (value) => {
    return (R.isEmpty(value))? "-" : value
  }

  getStatusText(status) {
    const statusMap = {
      true: "Active",
      false: "Pending delete"
    };

    return statusMap[status]
  }

  getStatusClassBadge(status) {
    const statusMap = {
      true: "success",
      false: "warning"
    };

    return statusMap[status]
  }

  render(){
    const { isLoading, data: userData, state: userState, error: userError } = this.props.userStore
    const user = toJS(userData)
    const state = toJS(userState)
    const error = toJS(userError)
    const openModalFailure = this.state.modalFailure.open
    const { t } = this.props

    if(isLoading){
      return null
    }

    return(
      <div>
        <Container fluid className="px-0">
          {
            state.get === GET_USER.FAILURE && openModalFailure && 
            <ErrorAlert error={error} />
          }
        </Container>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle title={t("User Detail")} className="text-sm-left mb-3" />
            <Col sm="2" className="d-flex ml-auto my-auto">
              <Button className="mx-auto ml-sm-auto mr-sm-0" theme="primary" onClick={() => this.goBack()}>
                &larr; {t("Go Back")}
              </Button>
              {/* <Button className="mx-auto ml-sm-auto mr-sm-0" theme="danger" onClick={() => this.toggleConfirm()}>
                Delete
              </Button> */}
            </Col>
          </Row>

          <Row>
            <Col lg="8" className="mx-auto mt-4">
              <Card small className="edit-user-details mb-4">

                <CardBody className="p-0">
                  <Form className="py-4" onSubmit={this.handleFormSubmit}>
                    <FormSectionTitle
                      title={t("Basic Information")}
                      description=""
                    />

                    <Row form className="mx-4">
                      <Col lg="12">
                        <Row form>
                          <Col md="6" className="form-group">
                            <label htmlFor="contactName">{t("Email")}:</label>
                            <p>{this.showTextValue(user.email)}</p>
                          </Col>

                          <Col md="6" className="form-group">
                            <label htmlFor="contactName">{t("Status")}:</label>
                            <p>
                              <Badge pill theme={this.getStatusClassBadge(user.active)}>
                                {this.getStatusText(user.active)}
                              </Badge>
                            </p>
                          </Col>
                        </Row>
                      </Col>

                      <Col lg="12">
                        <Row form>
                          <Col md="6" className="form-group">
                            <label htmlFor="contactName">{t("Created Date")}:</label>
                            <p>{moment(user.createDate).format('DD MMM YYYY HH:mm:ss')}</p>
                          </Col>

                          <Col md="6" className="form-group">
                            <label htmlFor="contactSurName">{t("Updated Date")}:</label>
                            <p>{moment(user.updateDate).format('DD MMM YYYY HH:mm:ss')}</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                  </Form>
                </CardBody>
              </Card>
            </Col>

          </Row>

        </Container>
      </div>
    )
  }
}


export default withTranslation()(UserInfo)