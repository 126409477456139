import React from "react";
import { Button, Alert } from "shards-react";

const ErrorAlert = ({ error, theme = "danger" }) => {
  let errorMessage = "Failure: "
  const defaultError = "Somthing went wrong"
  // let isNotfound = false

  // console.log({error})

  if(error === null || error.response === undefined) {
    errorMessage += defaultError
  }else if (error.code === 'ECONNABORTED') {
    errorMessage += "Connection timeout"
  }else if(error.response != null){
    if(error.response.status === 404) {
      // isNotfound = true
      errorMessage += `URL ${error.response.statusText}`
    }else{
      const err = error.response.data.error
      errorMessage += (err.isUserAlert) ? err.message : defaultError
    }
  }

  return (
    <div>
      {
        // !isNotfound &&
        <Alert theme={theme} className="mb-0 alert-dismissible" fade={true} transition={{ timeout: 10 }}>
          <Button className="mx-auto close" theme={theme} data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">×</span>
          </Button>
          <i className="fa fa-exclamation mx-2" aria-hidden="true" />
          {errorMessage}
        </Alert>
      }
    </div>
  )
};

export default ErrorAlert;
