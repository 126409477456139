import React, { Component } from "react";
import ReactTable from "react-table";
import { Container, Row, Card, CardBody, Button, CardHeader, ButtonGroup, Col, Badge } from "shards-react";
import { inject, observer } from "mobx-react";
import { toJS } from 'mobx';
import PageTitle from "../../components/common/PageTitle";
import LoadingBar from '../../components/common/LoadingBar';
import { ModalPop } from "../../components/common/MadalPop";
import ErrorAlert from "../../components/alert/ErrorAlert";
import { DELETE_REASON, GET_ALL_REASON, CREATE_REASON } from "../../stores/ReasonStore";
import { ModalAddReason } from "../../components/common/ModalAddReason";
import { withTranslation } from 'react-i18next';

@inject('reasonStore')
@observer
class Reason extends Component {

  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
      modalConfirm: {
        open: false,
        id: ""
      },
      page: 1,
      modalFailure: {
        open: true
      },
      modalAdd: {
        open: false
      }
    }
  }

  componentDidMount(){
    this.init()
  }

  componentDidUpdate(){
    const { state, clearState } = this.props.reasonStore
    const st = toJS(state)

    if(st.delete === DELETE_REASON.SUCCESS || st.create === CREATE_REASON.SUCCESS){
      clearState()
      this.init()
    } 
  }

  componentWillUnmount(){
    this.props.reasonStore.clearState()
  }

  init = () => {
    this.props.reasonStore.getAll()
    this.showProgressBar()
  }

  showProgressBar = async () => {
    const { isLoading } = this.props.reasonStore
    if(isLoading){
      const interval = setInterval(() => {
        this.setState({ progress: this.state.progress + 10 })
        if (this.state.progress >= 100 || !isLoading) clearInterval(interval);
      }, 20);
    }
  }
  
  handleItemDelete = () => {
    this.props.reasonStore.delete(this.state.modalConfirm.id)
    this.toggleConfirm()
  }

  toggleConfirm = (id) => {
    this.setState({ modalConfirm: { open: !this.state.modalConfirm.open, id: id || "" } });
  }

  toggleAdd = () => {
    this.setState({ modalAdd: { open: !this.state.modalAdd.open } });
  }

  handleAddReason = (reason) => {
    this.props.reasonStore.create(reason)
    this.toggleAdd()
  }

  // setTimeoutAlert = () => {
  //   setTimeout(() => {
  //     this.setState({ modalFailure: { open: false } })
  //   }, 3000)
  // }

  render(){
    const { datas, isLoading, totalPage, state: reasonState, error: reasonError } = this.props.reasonStore
    const data = toJS(datas)
    const state = toJS(reasonState)
    const error = toJS(reasonError)
    const openModalFailure = this.state.modalFailure.open
    const { t } = this.props

    return(
      <div>
        <Container fluid className="px-0">
          <LoadingBar isLoading={isLoading} progress={this.state.progress} theme="primary" className="mb-0" />
          {
            (state.get === GET_ALL_REASON.FAILURE || state.delete === DELETE_REASON.FAILURE || state.create === CREATE_REASON.FAILURE) && openModalFailure && 
            <div>
              <ErrorAlert error={error} />
              {/* {openModalFailure && this.setTimeoutAlert()} */}
            </div>
          }
        </Container>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle title={t("Reason for delete pet")} className="text-sm-left mb-3" />
            <Col sm="1" className="d-flex ml-auto my-auto" >
              <Button type="button" size="sm" onClick={() => this.toggleAdd()}>{t('Add Reason')}</Button>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <Card className="p-0" style={{ marginBottom: 30 }}>
                <CardHeader className="p-0">
                  <Container fluid className="file-manager__filters border-bottom text-right">
                    {/* <Button type="button" size="sm" >Add Reason</Button> */}
                  </Container>
                </CardHeader>
                <CardBody className="p-0">
                <table className="table mb-0">
                  <thead className="py-2 bg-light text-semibold border-bottom">
                    <tr>
                      <th className="text-center">{t('Reason')}</th>
                      <th className="text-right">{t('Actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.length === 0 ?
                      <tr>
                        <td colSpan="2" className="text-center">- - - Data not found - - -</td>
                      </tr>
                      :
                      data.map((item, idx) => (
                      <tr key={idx}>
                        <td className="lo-stats__status">
                          {item.text}
                        </td>
                        <td className="lo-stats__actions">
                          <ButtonGroup className="d-table ml-auto">
                            <Button theme="danger" onClick={() => this.toggleConfirm(item.id)}>
                              <i className="material-icons">&#xE872;</i>
                            </Button>
                          </ButtonGroup>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ModalPop
            title="Are you sure?"
            text="You won't be able to revert this!"
            confirmTextBtn="Yes, delete it"
            confirmFnBtn={() => this.handleItemDelete()}
            calcelFnBtn={() => this.toggleConfirm()}
            open={this.state.modalConfirm.open}
          />

          <ModalAddReason
            confirmFnBtn={(reason) => this.handleAddReason(reason)}
            calcelFnBtn={() => this.toggleAdd()}
            open={this.state.modalAdd.open}
          />

        </Container>
      </div>
    )
  }
}

export default withTranslation()(Reason)
