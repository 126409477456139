import axios from 'axios';
import {  observable, runInAction, action } from 'mobx';
import * as R from 'ramda'
import { path, v1 } from '../utils/api';
import buildActionTypes from '../utils/buildAction';
import StateHandling from '../utils/stateHandling'

export const GET_ALL_PET = buildActionTypes("GET_ALL_PET")
export const GET_PET = buildActionTypes("GET_PET")
export const DELETE_PET = buildActionTypes("DELETE_PET")
export const GET_PET_BY_SHELTER_ID = buildActionTypes("GET_PET_BY_SHELTER_ID")
export const GET_REPORT_HISTORY = buildActionTypes("GET_REPORT_HISTORY")

const initialState = {
  "get": null,
  "delete": null,
  "getReportHistory": null
}

const initialData = {
  id: "",
  name: "",
  breed: "",
  dimension: "",
  dayOfBirth: "",
  monthOfBirth: "",
  yearOfBirth: "",
  description: "",
  photoImgUrls: [],
  gender: "",
  conditions: {},
  health: "",
  age: 0
}

class PetStore extends StateHandling {
  @observable state = initialState
  @observable data = initialData
  @observable datas = []
  @observable error = null
  @observable isLoading = false
  @observable totalPage = 0
  @observable report = []

  @action clearState = () => {
    if (!R.isNil(this.state)) { this.state = initialState }
  }

  @action clearData = () => {
    if (!R.isNil(this.data)) {
      this.data = initialData
    }
    if (!R.isNil(this.datas)) {
      this.datas = []
    }
    this.totalPage = 0
  }

  @action getAll = async (offset = 0, limit = 0) => {
    try {
      runInAction(() => { 
        this.state.get = GET_ALL_PET.REQUEST
        this.isLoading = true
      })
      const response = await axios({
        method: "get",
        url: `${path}/${v1}/pets/web?page=${offset}&limit=${limit}`,
      })
      runInAction(() => {
        this.state.get = GET_ALL_PET.SUCCESS
        this.isLoading = false
        this.datas = response.data.data
        this.totalPage = response.data.totalPage
      })
    } catch (error) {
      runInAction(() => {
        this.state.get = GET_ALL_PET.FAILURE
        this.isLoading = false
        this.error = error
      })
    }
  }

  @action getById = async (id) => {
    try {
      runInAction(() => { 
        this.state.get = GET_PET.REQUEST
        this.isLoading = true
      })
      const response = await axios({
        method: "get",
        url: `${path}/${v1}/pets/${id}`,
      })
      runInAction(() => {
        this.state.get = GET_PET.SUCCESS
        this.isLoading = false
        this.data = response.data.data
      })
    } catch (error) {
      runInAction(() => {
        this.state.get = GET_PET.FAILURE
        this.isLoading = false
        this.error = error
      })
    }
  }

  @action delete = async (data) => {
    try {
      runInAction(() => { 
        this.state.delete = DELETE_PET.REQUEST
        this.isLoading = true
      })
      const response = await axios({
        method: "post",
        url: `${path}/${v1}/pets/delete`,
        data
      })
      runInAction(() => {
        this.state.delete = DELETE_PET.SUCCESS
        this.isLoading = false
      })
    } catch (error) {
      runInAction(() => {
        this.state.delete = DELETE_PET.FAILURE
        this.isLoading = false
        this.error = error
      })
    }
  }

  @action getByShelterId = async (id) => {
    try {
      runInAction(() => { 
        this.state.get = GET_PET_BY_SHELTER_ID.REQUEST
        this.isLoading = true
      })
      const response = await axios({
        method: "get",
        url: `${path}/${v1}/pets/shelter/${id}`,
      })
      runInAction(() => {
        this.state.get = GET_PET_BY_SHELTER_ID.SUCCESS
        this.isLoading = false
        this.datas = response.data.data
      })
    } catch (error) {
      runInAction(() => {
        this.state.get = GET_PET_BY_SHELTER_ID.FAILURE
        this.isLoading = false
        this.error = error
      })
    }
  }

  @action getReportHistory = async () => {
    try {
      runInAction(() => { 
        this.state.getReportHistory = GET_REPORT_HISTORY.REQUEST
        this.isLoading = true
      })
      const response = await axios({
        method: "get",
        url: `${path}/${v1}/reports/pet`,
      })
      runInAction(() => {
        this.state.getReportHistory = GET_REPORT_HISTORY.SUCCESS
        this.isLoading = false
        this.report = response.data.data
      })
    } catch (error) {
      runInAction(() => {
        this.state.getReportHistory = GET_REPORT_HISTORY.FAILURE
        this.isLoading = false
        this.error = error
      })
    }
  }

}

export default PetStore
