
import { action } from 'mobx';
import * as R from 'ramda';

export default class StateHandling {
  @action
  clearError = () => {
    if (!R.isNil(this.error)) { this.error = null }
  }
}
