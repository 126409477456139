import axios from 'axios';


export const AxiosInterceptor = () => {
  axios.defaults.timeout = 10000 //10s
  axios.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
  )
}

const errorHandler = (error) => {
  // if (isHandlerEnabled(error.config)) {
  //   // Handle errors
  // }
  if(error.response != null) {
    if(error.response.status === 401){
      window.location.replace('/login')
    }
  }
  
  return Promise.reject({ ...error })
}

const successHandler = (response) => {
  // if (isHandlerEnabled(response.config)) {
  //   // Handle responses
  // }
  return response
}